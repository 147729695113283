import type { ColorConfig } from '~/types/colorConfig'
import type { Company } from '~/types/company'

export const useCompany = defineStore('company', () => {
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const company: Ref<Company | undefined> = useState(
    'company:me',
    () => undefined,
  )
  const loading: Ref<boolean> = useState(
    'company:loading',
    () => false,
  )

  async function createCompany(companyData: Company) {
    const { logo, favicon, ...rest } = companyData

    const logoData = await fileToBuffer(logo)
    const faviconData = await fileToBuffer(favicon)
    try {
      const newCompany = await $fetch<any>(
        `${baseApiURL}/api/companies/`,
        {
          method: 'post',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            ...rest,
            logo: logoData,
            favicon: faviconData,
          },
        },
      )
      company.value = newCompany.data

      return newCompany.data
    } catch (e) {
      console.error(e)

      throw e
    }
  }

  async function updateCompany(companyData: Company) {
    try {
      const { logo, favicon, ...rest }: any = companyData

      const logoData = await fileToBuffer(logo)
      const faviconData = await fileToBuffer(favicon)

      const updatedCompany: Company = await $fetch(
        `${baseApiURL}/api/companies/${companyData.id}`,
        {
          method: 'patch',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            ...rest,
            logo: logoData,
            favicon: faviconData,
          },
        },
      )

      company.value = updatedCompany
      return updatedCompany
    } catch (e) {
      console.error(e)

      throw e
    }
  }

  async function updateColorConfig(
    colorConfig: ColorConfig,
  ) {
    try {
      //ts-ignore
      await $fetch(`${baseApiURL}/api/colorConfig`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
        body: {
          ...colorConfig,
          company_id: company.value?.id,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  async function getCompanyHelper(
    companyId: number,
  ): Promise<Company> {
    loading.value = true

    try {
      //ts-ignore
      const fetchedCompany: Company = await $fetch(
        `${baseApiURL}/api/companies/${companyId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
      return fetchedCompany
    } catch (e) {
      console.error(e)
      return {} as Company
    } finally {
      loading.value = false
    }
  }

  async function fetchCompanyData(
    companyId: number,
  ): Promise<Company> {
    try {
      //ts-ignore
      return await $fetch(
        `${baseApiURL}/api/companies/${companyId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
    } catch (e) {
      console.error(e)
      return {} as Company
    }
  }

  async function getCompany(companyId: number) {
    loading.value = true

    try {
      const fetchedCompany =
        await fetchCompanyData(companyId)
      company.value = fetchedCompany
    } finally {
      loading.value = false
    }
  }

  async function getCompanyWithDefaultColorConfig(
    companyId: number,
  ) {
    loading.value = true

    try {
      const fetchedCompany =
        await fetchCompanyData(companyId)

      const defaultValue: ColorConfig = {
        id: 0,
        company_id: 0,
        company: {} as Company,
        back_btn_text: fetchedCompany.text_color,
        back_btn_icon: fetchedCompany.icon_color,
        back_btn_bg: fetchedCompany.additional_color,
        header_bg: '#FFFFFF',
        header_text_fill: fetchedCompany.text_color,
        header_text_stroke: 'FFFFFF',
        header_icon: fetchedCompany.icon_color,
        footer_btn_text: fetchedCompany.text_color,
        footer_btn_stroke: fetchedCompany.text_color,
        footer_above_text: fetchedCompany.text_color,
        footer_above_icon: fetchedCompany.icon_color,
      }

      const mergedColorConfig: any = {
        ...defaultValue,
        ...fetchedCompany.colorConfig,
      }

      Object.keys(mergedColorConfig).forEach((key) => {
        const typedKey = key as keyof ColorConfig
        if (mergedColorConfig[typedKey] === null) {
          mergedColorConfig[typedKey] =
            defaultValue[typedKey]
        }
      })

      company.value = {
        ...fetchedCompany,
        colorConfig: mergedColorConfig,
      }
    } finally {
      loading.value = false
    }
  }

  function deleteCompany(companyId: number) {
    try {
      $fetch(`${baseApiURL}/api/companies/${companyId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  async function deleteLanguage(languageId: number) {
    try {
      await $fetch(
        `${baseApiURL}/api/companyLanguage/${languageId}/companyId/${company.value?.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async function getAllCompanys(): Promise<
    Company[] | undefined
  > {
    loading.value = true

    try {
      return await $fetch(`${baseApiURL}/api/companies/`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${useCookie('auteba').value}`,
        },
      })
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  return {
    company,
    loading,

    getCompany,
    getAllCompanys,

    createCompany,
    updateCompany,

    deleteCompany,
    deleteLanguage,

    getCompanyWithDefaultColorConfig,
    updateColorConfig,
  }
})
